import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import Header from "../components/Header";

export default function MarkdownPage({ data, location }) {
  const post = data.markdownRemark;

  return (
    <Layout
      preTitle={post.frontmatter.title}
      description={post.frontmatter.description}
      pathname={location.pathname}
    >
      <Header />
      <div
        css={css`
          max-width: 42.5rem;
          margin: 0 auto;
          padding: 0 1rem 5rem 1rem;
          flex-grow: 1;

          ol {
            list-style-type: lower-alpha;
          }
        `}
      >
        {post.frontmatter.title && <h1>{post.frontmatter.title}</h1>}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
      <div
        css={css`
          margin: 0 auto;
        `}
      >
        <StaticImage
          alt="Illustration"
          src="../assets/illustrations/Rocket_Pen.png"
          width={540}
          formats={["auto", "webp", "avif"]}
          placeholder={"tracedSVG"}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
